import React from 'react';
import './Privacy.scss';
import {Link} from "react-router-dom";

const Privacy = () => {
    return (
      <div className="privacy">
          <div className="bread-crumbs">
              <Link to="/" className="bread-crumbs-link">Главная</Link>
              <Link to="/loyalty" className="bread-crumbs-link">Программа лояльности</Link>
              <Link to="/loyalty/privacy" className="bread-crumbs-link">Политика конфиденциальности</Link>
          </div>
          <div className="privacy__section">
              <h4 className="privacy__sectionTitle">Политика обработки персональных данных </h4>
              <h5 className="privacy__subtitle">1.	Общие положения</h5>
              <p className="privacy__text">1.1.	Оператором обработки персональных данных посетителей сайта https://shoppingcenter-gallery-chizhov.ru (далее по тексту - сайт) является Общество с ограниченной ответственностью «Перспектива», адрес места нахождения: 394036, Воронежская обл., г. Воронеж, ул. Мира, 8А, офис 1 (далее по тексту - ООО «Перспектива», «Оператор»).</p>
              <p className="privacy__text">1.2.	Важнейшим условием реализации целей деятельности ООО «Перспектива» является обеспечение необходимого и достаточного уровня информационной безопасности информации, к которой, в том числе, относятся персональные данные.</p>
              <p className="privacy__text">1.3.	Основные понятия, используемые в настоящей Политике:</p>
              <p className="privacy__text"><span className="privacy__highlighted">Персональные данные - </span>любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных);</p>
              <p className="privacy__text"><span className="privacy__highlighted">Оператор персональных данных (оператор) - </span>ООО «Перспектива», самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
              <p className="privacy__text"><span className="privacy__highlighted">Субъект персональных данных - </span>посетитель сайта - физическое лицо, принявшее настоящую Политику обработки персональных данных путем предоставления Оператору своих персональных данных в требуемом Оператором составе и объеме.</p>
              <p className="privacy__text"><span className="privacy__highlighted">Обработка персональных данных - </span>любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя, в том числе:</p>
              <p className="privacy__list">- сбор;</p>
              <p className="privacy__list">- запись;</p>
              <p className="privacy__list">- систематизацию;</p>
              <p className="privacy__list">- накопление;</p>
              <p className="privacy__list">- хранение;</p>
              <p className="privacy__list">- уточнение (обновление, изменение);</p>
              <p className="privacy__list">- извлечение;</p>
              <p className="privacy__list">- использование;</p>
              <p className="privacy__list">- передачу (распространение, предоставление, доступ);</p>
              <p className="privacy__list">- обезличивание;</p>
              <p className="privacy__list">- блокирование;</p>
              <p className="privacy__list">- удаление;</p>
              <p className="privacy__list">- уничтожение.</p>
              <p className="privacy__text"><span className="privacy__highlighted">Автоматизированная обработка персональных данных - </span>обработка персональных данных с помощью средств вычислительной техники;</p>
              <p className="privacy__text"><span className="privacy__highlighted">Распространение персональных данных - </span>действия, направленные на раскрытие персональных данных неопределенному кругу лиц;</p>
              <p className="privacy__text"><span className="privacy__highlighted">Предоставление персональных данных - </span>действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
              <p className="privacy__text"><span className="privacy__highlighted">Блокирование персональных данных - </span>временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
              <p className="privacy__text"><span className="privacy__highlighted">Уничтожение персональных данных - </span>действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;</p>
              <p className="privacy__text"><span className="privacy__highlighted">Обезличивание персональных данных - </span>действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</p>
              <p className="privacy__text"><span className="privacy__highlighted">Информационная система персональных данных - </span>совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
              <p className="privacy__text">1.4.	Политика в отношении обработки персональных данных посетителей сайта (далее - Политика) определяет любое действие (операцию) или совокупность действий (операций), по сбору, записи, систематизации, накоплению, хранению, уточнению (обновлению, изменению), извлечению, использованию, передаче (распространение, предоставление, доступ), обезличиванию, блокированию, удалению, уничтожению персональных данных, а также сведения о реализуемых требованиях к защите персональных данных.</p>
              <p className="privacy__text">1.5.	Политика разработана в соответствии с действующим законодательством РФ и обработка персональных данных основана на следующих нормативных правовых актах:</p>
              <p className="privacy__list">- Конституции Российской Федерации;</p>
              <p className="privacy__list">- Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных»;</p>
              <p className="privacy__list">- Федерального закона «Об информации, информационных технологиях и о защите информации» от 27.07.2006 № 149-ФЗ;</p>
              <p className="privacy__list">- Постановление Правительства РФ от 15.09.2008 №687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;</p>
              <p className="privacy__list">- Постановление Правительства РФ от 01.11.2012 №1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;</p>
              <p className="privacy__list">- Приказ ФСТЭК России от 18.02.2013 №21 «Об утверждении состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;</p>
              <p className="privacy__list">- Приказ Роскомнадзора от 05.09.2013 №996 «Об утверждении требований и методов по обезличиванию персональных данных»;</p>
              <p className="privacy__list">- Иные нормативные правовые акты Российской Федерации и нормативные документы уполномоченных органов государственной власти.</p>
              <p className="privacy__text">1.6.	ООО «Перспектива» осуществляет обработку персональных данных посетителей сайта на законной и справедливой основе.</p>
              <p className="privacy__text">1.7.	ООО «Перспектива» вправе поручить в соответствии с ч. 3 ст. 6 №152-ФЗ от 27.07.2006 «О персональных данных» обработку персональных данных субъекта персональных данных другим лицам. При этом ответственность перед субъектом персональных данных за действия указанного лица несет оператор.</p>
              <h5 className="privacy__subtitle">2.	Цели обработки персональных данных</h5>
              <p className="privacy__text">2.1.	Персональные данные посетителей сайта обрабатываются ООО «Перспектива» в следующих целях:</p>
              <p className="privacy__list">- организации и проведения программ лояльности, маркетинговых и/или рекламных акций, исследований, опросов;</p>
              <p className="privacy__list">- продвижения услуг и/или товаров на рынке;</p>
              <p className="privacy__list">- для обработки вопросов, жалоб и предложений от посетителей сайта, информирования посетителей сайта о услугах и/или товарах с помощью различных средств связи, включая, но не ограничиваясь: по телефону с использованием специальных программ обмена сообщений, смс - рассылки сообщений, по электронной почте, почтовой рассылке, через форму обратной связи на сайте, и иными не запрещенными способами в иных законных целях.</p>
              <h5 className="privacy__subtitle">3.	Состав персональных данных и сроки их обработки</h5>
              <p className="privacy__text">3.1.	Все обрабатываемые ООО «Перспектива» персональные данные являются конфиденциальной, строго охраняемой информацией в соответствии с законодательством.</p>
              <p className="privacy__text">3.2.	В состав персональных данных, подлежащих обработке, в зависимости от целей, сроков и необходимости обработки могут включаться: ФИО; номер сотового (мобильного телефона), адрес электронной почты.</p>
              <p className="privacy__text">3.3.	Срок хранения составляет - 10 (десять) лет с даты получения соответствующих персональных данных</p>
              <h5 className="privacy__subtitle">4.	Порядок сбора, хранения, передачи и иных видов обработки персональных данных</h5>
              <p className="privacy__text">4.1.	Обработка персональных данных происходит автоматизированным и неавтоматизированным способом.</p>
              <p className="privacy__text">4.1.1.	Обработка персональных данных, осуществляемая без использования средств автоматизации, осуществляется таким образом, чтобы в отношении каждой категории персональных данных можно было определить места хранения персональных данных (материальных носителей). Оператором установлен перечень лиц, осуществляющих обработку персональных данных либо имеющих к ним доступ. Обеспечивается раздельное хранение персональных данных (материальных носителей), обработка которых осуществляется в различных целях. Оператор обеспечивает сохранность персональных данных и принимает меры, исключающие несанкционированный доступ к персональным данным.</p>
              <p className="privacy__text">4.1.2.	Обработка персональных данных, осуществляемая с использованием средств автоматизации, проводится при условии выполнения следующих действий:</p>
              <p className="privacy__list">- Оператор проводит технические мероприятия, направленные на предотвращение несанкционированного доступа к персональным данным и (или) передачи их лицам, не имеющим права доступа к такой информации;</p>
              <p className="privacy__list">- защитные инструменты настроены на своевременное обнаружение фактов несанкционированного доступа к персональным данным;</p>
              <p className="privacy__list">- технические средства автоматизированной обработки персональных данных изолированы в целях недопущения воздействия на них, в результате которого может быть нарушено их функционирование;</p>
              <p className="privacy__list">- Оператор производит резервное копирование данных с тем, чтобы иметь возможность незамедлительного восстановления персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</p>
              <p className="privacy__list">- осуществляется постоянный контроль за обеспечением уровня защищенности персональных данных.</p>
              <p className="privacy__text">4.2.	К обработке персональных данных допускаются только лица, прошедшие определенную процедуру допуска, к которой относятся:</p>
              <p className="privacy__list">- ознакомление лица с локальными нормативными актами, регламентирующими порядок и процедуру работы с персональными данными;</p>
              <p className="privacy__list">- взятие с сотрудника подписки о соблюдении конфиденциальности в отношении персональных данных при работе с ними;</p>
              <p className="privacy__list">- получение сотрудником и использование в работе индивидуальных атрибутов доступа к информационным системам, содержащим в себе персональные данные. При этом каждому сотруднику выдаются минимально необходимые для исполнения трудовых обязанностей права на доступ в информационные системы персональных данных.</p>
              <p className="privacy__text">Сотрудники, имеющие доступ к персональным данным, получают только те персональные данные, которые необходимы им для выполнения конкретных трудовых обязанностей.</p>
              <p className="privacy__text">4.3.	Персональные данные хранятся в электронном виде.</p>
              <p className="privacy__text">4.3.1.	В электронном виде персональные данные хранятся в базах данных информационных систем персональных данных, а также в архивных (резервных) копиях баз данных этих информационных систем персональных данных.</p>
              <p className="privacy__text">При хранении персональных данных соблюдаются организационные и технические меры по обеспечению их сохранности и исключению несанкционированного доступа к ним, в том числе, но не исключительно:</p>
              <p className="privacy__list">- назначение сотрудника, ответственного за обработку персональных данных;</p>
              <p className="privacy__list">- ограничение физического доступа к местам обработки персональных данных, включая установку запирающих устройств, ограничение круга лиц, имеющих доступ и т.п.;</p>
              <p className="privacy__list">- земные электронные носители, на которых хранятся резервные копии персональных данных субъектов персональных данных (при необходимости маркируются и учитываются в журнале регистрации, учета и выдачи внешних носителей для хранения резервных копий персональных данных);</p>
              <p className="privacy__list">- учет всех информационных систем, электронных носителей, а также архивных копий</p>
              <p className="privacy__list">- применение сертифицированных средств защиты информации и средств криптографической защиты информации.</p>
              <p className="privacy__text">Подробный порядок регламентируется внутренними локальными актами Оператора.</p>
              <p className="privacy__text">4.4.	Для целей обработки данных ООО «Перспектива» может передавать персональные данные:</p>
              <p className="privacy__list">- ответственным сотрудникам;</p>
              <p className="privacy__list">- третьим лицам, подписавшим обязательство по обеспечению конфиденциальности и безопасности полученных сведений по поручению ООО «Перспектива», где должны быть указаны перечень действий (операций) с персональными данными, которые будут совершаться лицом, осуществляющим обработку персональных данных, и цели обработки, должна быть установлена обязанность такого лица соблюдать конфиденциальность персональных данных и обеспечивать безопасность персональных данных при их обработке, а также должны быть указаны требования к защите обрабатываемых персональных данных в соответствии со статьей 19 Федерального закона №152-ФЗ «О персональных данных»;</p>
              <p className="privacy__list">- по запросу органов дознания и следствия, суда в связи с проведением расследования или судебным разбирательством, по запросу органа уголовно-исполнительной системы в связи с исполнением уголовного наказания и осуществлением контроля за поведением условно осужденного, осужденного, в отношении которого отбывание наказания отсрочено, и лица, освобожденного условно-досрочно.</p>
              <p className="privacy__text">Передача персональных данных третьим лицам в остальных случаях возможна только с согласия субъекта персональных данных и только с целью исполнения обязанностей перед субъектом персональных данных.</p>
              <p className="privacy__text">При передаче персональных данных в электронном виде третьим лицам по открытым каналам связи ООО «Перспектива» обязано принимать все необходимые меры по защите передаваемой информации в соответствии с требованиями действующего законодательства РФ в порядке, регламентированным локальными актами.</p>
              <p className="privacy__text">4.5.	Изменение персональных данных производится на основании заявления субъекта персональных данных, на основании официальных документов, содержащих персональные данные субъекта персональных данных и в иных законных случаях.</p>
              <p className="privacy__text">4.6.	Персональные данные хранятся не дольше, чем этого требуют цели их обработки, и подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в их достижении.</p>
              <p className="privacy__text">Уничтожение с носителей и носителей, содержащих персональные данные субъектов, должно соответствовать следующим правилам:</p>
              <p className="privacy__list">- быть максимально надежным и конфиденциальным, исключая возможность последующего восстановления;</p>
              <p className="privacy__list">- оформляться соответствующим актом;</p>
              <p className="privacy__list">- должно проводиться комиссией по уничтожению персональных данных;</p>
              <p className="privacy__list">- уничтожение должно касаться только тех персональных данных, которые подлежат уничтожению в связи с достижением цели обработки указанных персональных данных либо утраты необходимости в их достижении, не допуская случайного или преднамеренного уничтожения актуальных носителей.</p>
              <h5 className="privacy__subtitle">5.	Права и обязанности субъекта персональных данных</h5>
              <p className="privacy__text">5.1.	Субъект персональных данных имеет право:</p>
              <p className="privacy__list">- требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;</p>
              <p className="privacy__list">- требовать перечень своих персональных данных, обрабатываемых Оператором и источник их получения;</p>
              <p className="privacy__list">- получать информацию о сроках обработки своих персональных данных, в том числе о сроках их хранения;</p>
              <p className="privacy__list">- обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействия при обработке его персональных данных;</p>
              <p className="privacy__list">- на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке;</p>
              <p className="privacy__list">- отозвать согласие на обработку персональных данных в предусмотренных законом случаях.</p>
              <p className="privacy__text">Получить запрашиваемую информацию либо отозвать согласие на обработку персональных данных субъект персональных данных может, обратившись с письменным запросом в ООО «Перспектива» по электронному адресу call-center@glch.ru Ответ, содержащий запрашиваемую информацию, либо мотивированный отказ в ее предоставлении направляется по адресу, указанному в запросе, в течение 30 (Тридцати) дней.</p>
              <p className="privacy__text">Порядок обработки запросов субъектов персональных данных по выполнению их законных прав производится согласно утвержденному внутреннему документу, разработанному в соответствии с действующим законодательством в области защиты персональных данных и подконтролен сотруднику, ответственному за обработку персональных данных.</p>
              <h5 className="privacy__subtitle">6.	Сведения о реализуемых требованиях к защите персональных данных</h5>
              <p className="privacy__text">6.1.	ООО «Перспектива» получает персональные данные от субъектов персональных данных и от третьих лиц (лиц, не являющихся субъектами персональных данных). При этом ООО «Перспектива» выполняет обязанности, предусмотренные №152-ФЗ от 27.07.2006 «О персональных данных» при сборе персональных данных.</p>
              <p className="privacy__text">6.2.	ООО «Перспектива» прекращает обработку персональных данных в следующих случаях:</p>
              <p className="privacy__list">- при наступлении условий прекращения обработки персональных данных или по истечении установленных сроков;</p>
              <p className="privacy__list">- по достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;</p>
              <p className="privacy__list">- по требованию субъекта персональных данных, если обрабатываемые в ООО «Перспектива» персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</p>
              <p className="privacy__list">- в случае выявления неправомерной обработки персональных данных, если обеспечить правомерность обработки персональных данных невозможно;</p>
              <p className="privacy__list">- в случае отзыва субъектом персональных данных согласия на обработку его персональных данных или истечения срока действия такого согласия (если персональные данные обрабатываются ООО «Перспектива» исключительно на основании согласия субъекта персональных данных);</p>
              <p className="privacy__list">- в случае ликвидации ООО «Перспектива».</p>
              <p className="privacy__text">6.3.	ООО «Перспектива» для обеспечения выполнения обязанностей, предусмотренных Федеральным законом РФ «О персональных данных» и принятыми в соответствии с ним нормативными правовыми актами, приняты следующие меры:</p>
              <p className="privacy__list">- назначено лицо, ответственное за организацию обработки персональных данных;</p>
              <p className="privacy__list">- изданы локальные акты по вопросам обработки и обеспечения безопасности персональных данных, а также локальные акты, устанавливающие процедуры, направленные на предотвращение и выявление нарушений законодательства РФ, устранение последствий таких нарушений:
                  настоящая Политика;
                  другие локальные акты по вопросам обработки и обеспечения безопасности персональных данных;
              </p>
              <p className="privacy__list">- применены правовые, организационные и технические меры по обеспечению безопасности персональных данных;</p>
              <p className="privacy__list">- осуществляется внутренний контроль соответствия обработки персональных данных требованиям №152-ФЗ от 27.07.2006 «О персональных данных» и принятых в соответствии с ним нормативных правовых актов, настоящей Политики, локальных актов ООО «Перспектива»;</p>
              <p className="privacy__list">- работники ООО «Перспектива», непосредственно осуществляющие обработку персональных данных, ознакомлены с положениями №152-ФЗ от 27.07.2006 «О персональных данных» и принятых в соответствии с ним нормативных правовых актов, настоящей Политики и локальных актов по вопросам обработки персональных данных</p>
              <p className="privacy__text">6.4.	В ООО «Перспектива» реализуются следующие требования к защите персональных данных:</p>
              <p className="privacy__list">- организован режим обеспечения безопасности помещений, в которых размещены информационные системы, препятствующий возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения;</p>
              <p className="privacy__list">- реализовано обеспечение сохранности носителей персональных данных;</p>
              <p className="privacy__list">- используются средства защиты информации, прошедшие процедуру оценки соответствия требованиям законодательства РФ в области обеспечения безопасности информации;</p>
              <p className="privacy__list">- реализованы требования, установленные Постановлением Правительства РФ от 15 сентября 2008 г. №687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации».</p>
              <h5 className="privacy__subtitle">7.	Положения по конфиденциальности и защите персональной информации</h5>
              <p className="privacy__text">7.1.	ООО «Перспектива» осуществляет обработку персональных данных субъектов, собранных на сайте.</p>
              <p className="privacy__text">К персональным данным, собранным на сайте, относятся:</p>
              <p className="privacy__list">- Адрес электронной почты посетителя сайта;</p>
              <p className="privacy__list">- Номер телефона посетителя сайта;</p>
              <p className="privacy__list">- Фамилию, имя, отчество посетителя сайта;</p>
              <p className="privacy__text">7.2.	Оператор записывает cookies на устройство посетителя сайта, которое клиент использует для реализации своих потребностей на сайте. Cookies - это небольшие фрагменты данных, отправленные веб-сервером и хранимые на электронном устройстве посетителя сайта. Они используются для упрощения клиентского опыта пользования сайтом, сбора аналитики Оператором для улучшения качества предоставляемых услуг на сайте, формирования статистики использования сайта и оптимизации рекламных сообщений. Cookies не содержат конфиденциальную информацию. Посетитель сайта настоящим дает согласие на сбор, анализ и использование cookies, в том числе третьими лицами, для целей, указанных в настоящем пункте.</p>
              <p className="privacy__text">7.3.	Оператор получает информацию об ip-адресе посетителя сайта. Данная информация не используется для установления личности посетителя сайта.</p>
              <h5 className="privacy__subtitle">8.	Информация об Операторе</h5>
              <p className="privacy__text">8.1.	Общество с ограниченной ответственностью «Перспектива», адрес места нахождения: 394036, Воронежская обл., г. Воронеж, ул. Мира, 8А, офис 1 </p>
              <h5 className="privacy__subtitle">9.	Заключительные положения</h5>
              <p className="privacy__text">9.1.	Настоящая Политика подлежит изменению, дополнению в случае появления новых законодательных актов и специальных нормативных документов по обработке и защите персональных данных.</p>
              <p className="privacy__text">9.2.	Настоящая Политика является внутренним документом ООО «Перспектива», и подлежит размещению на сайте.</p>
              <p className="privacy__text">9.3.	Контроль исполнения требований настоящей Политики осуществляется ответственным за обеспечение безопасности персональных данных ООО «Перспектива».</p>
          </div>
      </div>
    );
}

export default Privacy
