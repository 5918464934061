import React from 'react';
import './DeleteLoyalty.scss';
import {Link} from "react-router-dom";

const DeleteLoyalty = () => {
    return (
        <div className="deleteLoyalty">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/loyalty" className="bread-crumbs-link">Программа лояльности</Link>
                <Link to="/loyalty/privacy" className="bread-crumbs-link">Удаление карты лояльности</Link>
            </div>
            <div className="deleteLoyalty__section">
                <h4 className="deleteLoyalty__sectionTitle">Удаление карты лояльности</h4>
                <p className="deleteLoyalty__text">
                    Для удаления учетной записи отправьте заявку нам на почту <a className="deleteLoyalty__link" href="mailto:call-center@glch.ru" target="_blank">call-center@glch.ru</a>
                </p>
            </div>
        </div>
    );
}

export default DeleteLoyalty